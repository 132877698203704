
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
})
export default class extends Vue {

  @Prop() score : number
  @Prop() count : number
  @Prop() misses : number

}
