
import { Component, Vue } from 'vue-property-decorator';
import Polina from "@/Components/Polina.vue"

@Component({
  components: {
    Polina
  },
})
export default class extends Vue {
}
