
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class App extends Vue {

  Name = "App"
  down = false;
  mouseX = -999;
  mouseY = -999;

  firstHit = true;
  score = 0;
  count = 0;
  misses = 0;
  time = 0;

  swapAudio = new Audio('sound/swat.mp3');
  crowdAudio = new Audio('sound/crowd_shock.mp3')
  applauseAudio = new Audio('sound/applause.mp3')
  dingAudio = new Audio('sound/ding.mp3')

  GetCursorStyle() : string {
      return "position: absolute; left: " + (this.mouseX - 30) + "px; top: " + (this.mouseY - 40) + "px; user-drag: none; pointer-events: none;";
  }

  GetCursorSrc() : string {
    if( this.down == true ) {
      return "/img/swat_128_rotated.png";
    }
    else {
      return "/img/swat_128.png";
    }
  }

  OnMove( event : MouseEvent ) : void {
    this.mouseX = event.x;
    this.mouseY = event.y;
  }

  OnClick( down : boolean ) : void {
    this.down = down;
    if( down) {
      this.swapAudio.play();
    }
  }

  OnScore( score : number ) : void {
    if( this.firstHit && score > 0 ) {
      this.firstHit = false;
      this.crowdAudio.play();
    }

    this.score += score;
    
    if( score > 0 ) this.count++;
    if( score < 0 ) this.misses++;
  }

  OnTime( time : number ) : void {
    this.time = time;
    if( time == 0) {
      this.applauseAudio.play();
      this.$router.push('/GameOver')
    }

    /*if( 
      time == 10 ||
      time == 5 ||
      time == 3 ||
      time == 2 ||
      time == 1
    )
    {
      this.dingAudio.play();
    }*/
  }

  OnGameStarted() : void {
    this.firstHit = true;
    this.score = 0;
    this.count = 0;
    this.misses = 0;
  }
}
