import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Welcome from '@/views/Welcome.vue'
import Game from '@/views/Game.vue'
import GameOver from '@/views/GameOver.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/Game',
    name: 'Game',
    component: Game
  },
  {
    path: '/GameOver',
    name: 'GameOver',
    component: GameOver
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
