
import { Component, Vue } from 'vue-property-decorator';
import Polina from "@/Components/Polina.vue"

@Component({
  components: {
    Polina
  },
})
export default class extends Vue {

  width = 100
  height = 100

  polinas = [
    { left: 0.4, top: 0.34, size: 0.05 },
    { left: 0.5, top: 0.5, size: 0.07 },
    { left: 0.275, top: 0.28, size: 0.1 },
    { left: 0.64, top: 0.47, size: 0.15 },
    { left: 0.11, top: 0.34, size: 0.2 },
    { left: 0.37, top: 0.45, size: 0.25 },
    { left: 0.85, top: 0.45, size: 0.225 },
  ]

  timer = 0
  time = 60

  mounted() : void {
    this.onResize();

    this.$emit('time-remaining', this.time)
    this.$emit('game-started')

    this.timer = setInterval( () => {
      if(this.time > 0) {
        this.time--;
        this.$emit('time-remaining', this.time)
      }
    }, 1000 )
  }

  onResize() : void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.width = this.$el.offsetWidth; 

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.height = this.$el.offsetHeight;
  }

  get AspectRatio() : number {
    return this.width / this.height;
  }

  get ImageWidth() : number {
    if( this.AspectRatio > 16/9 )
    {
      return this.height * (16/9);
    }
    return this.width;
  }

  get ImageHeight() : number {
    if( this.AspectRatio < 16/9 )
    {
      return this.width * (9/16);
    }
    return this.height;
  }

  get OffsetX() : number {
    if( this.AspectRatio > 16/9 )
    {
      return (this.width - this.ImageWidth) * 0.5;
    }
    return 0;
  }

  get OffsetY() : number {
    if( this.AspectRatio < 16/9 )
    {
      return 0;//(this.height - this.ImageHeight) * 0.5;
    }
    return 0;
  }

  OnScore( score : number ) : void {
    this.$emit('score', score)
  }

}
