
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class extends Vue {

  src = "img/squatting_polina.png"
  
  @Prop() left : number
  @Prop() top : number
  @Prop() size : number

  count = 0;
  timeout = -1;
  awaitingStand = false;

  buzzerAudio = new Audio('sound/buzzer1.mp3')
  splatAudio = new Audio( 'sound/splat1.mp3' )

  mounted() : void {
    this.AsyncStand()
  }

  AsyncStand() : void {
    if( this.awaitingStand ) return;

    this.awaitingStand = true;
    setTimeout( () => {
        this.awaitingStand = false;
        this.count = 5;
        this.Process();
      }, this.GetSleepTime() );
  }

  GetSleepTime() : number {
    return 100 * Math.floor(Math.random() * 50) + 500; 
  }

  GetImageStyle() : string {
      return "position: absolute; left: " + this.left + "px; top: " + this.top + "px; user-drag: none;";
  }
 
  OnPolinaClick() : void {

    if( this.count > 0 ) {
      setTimeout( () => {
        this.splatAudio.play();
      }, 125 )      
      this.$emit('score', this.count)
    }
    else {
      setTimeout( () => {
        this.buzzerAudio.play();
      }, 125 )      

      // -1 for clicking a squatting Polina
      this.$emit('score', -1)
    }

    this.count = 0;
    this.Process();
  }

  GetBadgeColor() : string {
    switch( this.count )
    {
      case 5:
      case 4:
      case 3:
        return "green";
      case 2:
        return "orange";
      case 1:
        return "red";
    }

    return "";
  }

  Process() : void {

    if( this.count > 0 ) {
      this.src = "img/standing_polina.png"

      this.timeout = setTimeout( () => {
        this.timeout = -1;
        this.count--;
        this.Process();
      }, 1000 )
    }
    else {
      this.src = "img/squatting_polina.png"
      if (this.timeout != -1) clearTimeout( this.timeout );
      this.AsyncStand();
    }

  }
}
